/****** BG COLORS *****/
$background-light: #fcfcfc;
$background-medium: #f5f5f5;
$background-dark: #e4e3e3;
$background-dark-medium: #e8e8e8;

/****** DIVIDER & BORDER COLORS *****/
$divider: #e1e1e1;

/****** HOVER / ACTIVE COLORS *****/
$hover-color: #f5f5f5;
$active-color: #e3f1fd;

/****** MAIN & ACCENT COLORS *****/
$primary-color: #222c64;
$primary-color-dark: #da7900;
$accent-color: #eaaa1f;
$accent-color-2: #e3b600;
$accent-color-3: #727272;

/****** TEXT COLORS *****/
$primary-text: #333333;
$secondary-text: #727272;
$light-gray-text: rgba(0, 0, 0, 0.4);
$placeholder-text: #c7c7cd;

/***** IDEAS COLORS *****/
$suggested: #2095f2;
$researching: #3e50b4;
$busy: #fec006;
$said-yes: #4bae4f;
$said-no: #e81d62;
$waiting: #9d9d9d;
